module.exports = {
  client_id: "h35ta34t0xann2dok3soro580mafys",
  redirect_uri: "https://recent-messages.zneix.eu/authorized",
  // human readable strings for the home page and API documentation
  messages_expire_after: "48 hours",
  channels_expire_after: "48 hours",
  sessions_expire_after: "7 days",
  max_buffer_size: "800",
  github_link: "https://github.com/robotty/recent-messages2",
  // used for both the documentation as well as the actual API calls made by the web app. Don't include a trailing slash
  api_base_url: "https://recent-messages.zneix.eu/api/v2",
};

if (process.env.NODE_ENV === "development") {
  module.exports = {
    ...module.exports,
    client_id: "h35ta34t0xann2dok3soro580mafys",
    redirect_uri: "http://localhost:1234/authorized",
    api_base_url: "http://127.0.0.1:2790/api/v2",
  };
}
